import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import CategoriesCard from 'components/Cards/Widgets/Categories';
import RecentPostsCard from 'components/Cards/Widgets/RecentPosts';
import { generateLogger } from 'utils/loggers';

const logger = generateLogger('categories_page');
const handleCategoryClick = (categoryName: string) => {
  logger.click('click_category', { categoryName });
};
const handleRecentPostClick = (path: string) => {
  logger.click('click_recent_posting', { path });
};

const CategoriesPage = () => {
  useEffect(() => {
    logger.view();
  }, []);

  return (
    <Layout right={<RecentPostsCard onClick={handleRecentPostClick} />}>
      <SEO title="카테고리" />
      <CategoriesCard onClickCategory={handleCategoryClick} />
    </Layout>
  );
};

export default CategoriesPage;
